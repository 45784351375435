.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  overflow: auto;
  font-family: 'SFPro';
  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  max-height: 95%;
  margin: 0 10px;
  background: #F2F3F5;
  //padding: 40px;
  border-radius: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 560px;
  width: 100%;
  position: relative;

  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #1D1E25;
  line-height: 1;
}

[data-theme="dark"] {
  .modal {
    background-color: #1D1E25;
    //color: #FFFFFF;
  }
  .closeButton {
    color: #F2F3F5;
  }
}